import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import SEO from "../components/seo"
import BlogItem from "../components/BlogItem"
import Navbar from "../components/Navbar"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Layout from "../components/Layout"

export default function Home({ data }) {
  const {
    allAirtable: { edges, totalCount },
  } = data

  const onRandomBlogClick = () => {
    const randomBlog = edges[Math.floor(Math.random() * edges.length)]
    window.open(randomBlog.node.data["URL"])
  }

  return (
    <Layout>
      <div
        css={css`
          margin-bottom: 30px;
          margin-top: 30px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 0;
            text-align: center;
          `}
        >
          All Blogs
        </h1>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
            text-align: center;
          `}
        >
          ({totalCount} blogs)
        </span>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
            text-align: center;
          `}
        >
          <button
            css={css`
              all: unset;
              color: #0178bd;
              cursor: pointer;
            `}
            onClick={onRandomBlogClick}
          >
            Surprise Me!
          </button>
        </span>
      </div>
      <div
        css={css`
          max-width: 600px;
          margin: 0 auto;
        `}
      >
        {edges.map(edge => {
          const {
            node: { data },
          } = edge
          return <BlogItem key={data["URL"]} data={data} />
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AirtableQuery {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
